import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StrorageService {
  constructor() { }

  getToken(): string | null {
    return localStorage.getItem('x-access-token');
  }
  getUserData(): string | null {
    return localStorage.getItem('employee-data');
  }
  setLoginDetails(data: object, token: string) {
    localStorage.setItem("employee-data", JSON.stringify(data));
    localStorage.setItem("x-access-token", token);
  }
  setPermissions(data: any) {
    localStorage.setItem("permission", JSON.stringify(data));
  }
  getPermission(): string | null {
    return localStorage.getItem('permission');
  }
}
